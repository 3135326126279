import React from "react";
import Routes from "./Routes";

import TagManager from "react-gtm-module";
import LinkedInTracking from "./li-tag-manager";
const tagManagerArgs = {
  gtmId: "GTM-N6K5475",
};
TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: "pageview",
  });
  
  return (<>
    <LinkedInTracking />
    <Routes />
  </>);
}

export default App;
