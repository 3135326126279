import React from 'react';
import { Helmet } from 'react-helmet';

const LinkedInTracking = () => {
  return (
    <Helmet>
      <script type="text/javascript">
          {`
            var _linkedin_partner_id = "7930433";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
      </script>
      <script type="text/javascript">
        {`
          (function(l) {
            if (!l) {
              window.lintrk = function(a, b) { window.lintrk.q.push([a, b]) };
              window.lintrk.q = [];
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";
            b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
          })(window.lintrk);
        `}
      </script>
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=7930433&fmt=gif"
        />
        `}
      </noscript>

      <script type="text/javascript">
        {`
          window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
          window._adftrack.push({
              HttpHost: 'track.adform.net',
              pm: 3592242,
              divider: encodeURIComponent('|'),
              pagename: encodeURIComponent('WebsiteName|SectionName|SubSection|PageName')
          });
          (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
        `}
      </script>
      <noscript>
        {`
          <p style="margin:0;padding:0;border:0;">
            <img src="https://track.adform.net/Serving/TrackPoint/?pm=3592242&ADFPageName=WebsiteName|SectionName|SubSection|PageName&ADFdivider=|" width="1" height="1" alt="" />
        </p>
        `}
      </noscript>
    </Helmet>
  );
};

export default LinkedInTracking;
